import React, { useEffect, useState } from "react";
import DropDown from "../../DropDown";
import TrestleButton from "../../TrestleButton";
import TrestleTable, {
  TableHeaderProps,
} from "../../TrestleTable/TrestleTable";
import { AlertTrianleIcon, DeleteItemCross } from "../../../../assets/svgs";
import InputTextField, { InputErrortext } from "../../InputTextField";
import { find, isEmpty } from "lodash";
import { RegExConstants } from "../../../../constants/RegExConstants";

interface CustomAttributesProps {
  expanded: any;
  customAttributesData: any;
  setCustomAttributesData: any;
  customAttributesDropdownValues: any;
  customDropError: string;
  setCustomDropError: any;
  disable?: boolean;
  availableProductOptions: any;
}

const booleanValues: any = {
  True: true,
  False: false,
};

const boolval: any = {
  true: "True",
  false: "False",
};

function DefaultValueField(props: any) {
  let item: any = props.item || {};

  if ([true, false].includes(item.defaultValue)) {
    return (
      <div className={`${item?.customError && "-mt-[15px]"}`}>
        <DropDown
          name={"booleanValue"}
          label={""}
          value={[boolval[`${item.customValue}`]]}
          error={item?.customError || ""}
          placeholder={"Search"}
          onChange={(val: any) => {
            props.OnChange(booleanValues[val], item);
          }}
          required={false}
          options={["True", "False"]}
          className="!h-[40px] text-[#163545]"
        />
      </div>
    );
  } else {
    return (
      <div className={`${item?.customError && "-mt-[15px]"}`}>
        <InputTextField
          name={"customValue"}
          label={""}
          value={item}
          error={{ customValue: item?.customError || "" }}
          type={"number"}
          onChange={(val: any) => {
            console.log("val", val.target);
            props.OnChange(val.target.value, item);
          }}
          disabled={false}
          height={"40px"}
        />
        {/* <DropDown
          name={"booleanValue"}
          label={""}
          value={[boolval[`${item.customValue}`]]}
          error={item?.customError || ""}
          placeholder={"Search"}
          onChange={(val: any) => {
            props.OnChange(booleanValues[val], item);
          }}
          required={false}
          options={["True", "False"]}
          className="!h-[40px] text-[#163545]"
        /> */}
      </div>
    );
  }
}

function CustomAttributes({
  expanded,
  customAttributesData,
  setCustomAttributesData,
  customAttributesDropdownValues,
  customDropError,
  setCustomDropError,
  disable = false,
  availableProductOptions,
}: CustomAttributesProps) {
  const [selectedAttribute, setselectedAttribute] = useState<any>("");
  const [customAttributes, setCustomAttributes] = useState<any>([
    ...customAttributesData,
  ]);

  const [
    filteredCustomAttributesDropdownValues,
    setCustomAttributesDropdownValues,
  ] = useState({});
  // const [customDropError, setCustomDropError] = useState<string>("");

  const OnChangeFlag = (val: any, ele: any, error: string = "") => {
    let index: number = customAttributes.indexOf(ele);
    let temp: any = [...customAttributes];
    let valueList: any = customAttributesDropdownValues[ele.productName];

    temp[index].flag = val;
    temp[index].defaultValue = valueList[val];
    temp[index].error = error;

    setCustomAttributes(temp);
    setCustomAttributesData(temp);
  };

  const OnCustomValueChange = (val: any, ele: any) => {
    let index: number = customAttributes.indexOf(ele);
    let temp: any = [...customAttributes];

    if (ele.flag === "batch-size") {
      if (RegExConstants.ONLY_NUMBERS.test(val.trim())) {
        const numericValue = parseInt(val, 10);
        if (numericValue > 250000) {
          temp[index].customError = "Limit cannot be more than 250K";
          temp[index].customValue = "";
        } else {
          temp[index].customError = "";
          temp[index].customValue = numericValue;
        }
      }
    }else if (ele.flag === "max_parallel_batches") {
      if (RegExConstants.ONLY_NUMBERS.test(val.trim())) {
        const numericValue = parseInt(val, 10);
        if (numericValue > 20) {
          temp[index].customError = "Max batches can't be more than 20";
          temp[index].customValue = "";
        } else {
          temp[index].customError = "";
          temp[index].customValue = numericValue;
        }
      }
    } else {
      temp[index].customValue = val;
      temp[index].customError = "";
    }

    setCustomAttributes(temp);
    setCustomAttributesData(temp);
  };


  const getDefaultValue = (ele:any) => {
    if(ele.flag === "batch-size") {
      return "100000";
    }else if(ele.flag === "max_parallel_batches") {
      return "0";
    }else{
     return boolval[`${ele.defaultValue}`]
    }
  }

  const headCellsAttributes: readonly TableHeaderProps[] = [
    {
      id: "productName",
      numeric: true,
      disablePadding: false,
      label: "Product Name",
      isCustomElements: true,
      width: "25%",
      customElements: (ele: any) => {
        return ele.productName || "Product Name ";
      },
      sortable: false,
    },
    {
      id: "flag",
      numeric: false,
      disablePadding: false,
      label: "Flag",
      width: "21%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <div className={`max-w-[250px] ${ele?.error && "-mt-[15px]"}`}>
            <DropDown
              name={"flag"}
              label={""}
              value={ele.flag}
              error={ele?.error || ""}
              placeholder={"Search"}
              onChange={(val: any) => {
                const { productName } = ele;
                const existingAttributes = find(customAttributesData, {
                  productName,
                  flag: val,
                });

                OnChangeFlag(
                  val,
                  ele,
                  existingAttributes ? "Duplicate attribute" : ""
                );
              }}
              required={false}
              options={
                Object.keys(customAttributesDropdownValues[ele.productName]) ||
                []
              }
              className="!h-[40px] text-[#163545]"
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "defaultValue",
      numeric: true,
      disablePadding: false,
      label: "Default",
      isCustomElements: true,
      width: "18%",
      customElements: (ele: any) => {
        return (
          <div className="max-w-[200px] w-full mr-5">
            <InputTextField
              name={"defaultValue"}
              label={""}
              value={{
                defaultValue: getDefaultValue(ele),
              }}
              error={{}}
              type={"text"}
              onChange={() => {}}
              disabled={true}
              height={"40px"}
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "customValue",
      numeric: true,
      disablePadding: false,
      label: "Custom",
      isCustomElements: true,
      width: "30%",
      customElements: (ele: any) => {
        return (
          <div className="max-w-[230px] w-full mr-5">
            <DefaultValueField item={ele} OnChange={OnCustomValueChange} />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "delete",
      numeric: true,
      disablePadding: false,
      label: "",
      isCustomElements: true,
      width: "6%",
      customElements: (ele: any) => {
        return (
          <div
            className="cursor-pointer hover:text-[#B23352]"
            onClick={() => {
              let temp: any = [...customAttributes];
              temp = deleteItem(ele, temp);
              setCustomAttributes(temp);
              setCustomAttributesData(temp);
            }}
          >
            {DeleteItemCross}
          </div>
        );
      },
      sortable: false,
    },
  ];

  const deleteItem = (ele: any, arr: any) => {
    let temp = [...arr];
    const index = temp.indexOf(ele);
    if (index > -1) {
      temp.splice(index, 1);
    }

    return temp;
  };

  useEffect(() => {
    const filteredKeys = Object.keys(customAttributesDropdownValues).filter(
      (key) => availableProductOptions.includes(key)
    );

    const filteredCustomAttributes: any = {};
    filteredKeys.forEach((key) => {
      filteredCustomAttributes[key] = customAttributesDropdownValues[key];
    });

    setCustomAttributesDropdownValues(filteredCustomAttributes);

    let temp = Object.keys(filteredCustomAttributes).sort()[0];
    if (temp == null) {
      temp = Object.keys(customAttributesDropdownValues).sort()[0];
    }
    const isHaveAttributes: boolean = isEmpty(
      customAttributesDropdownValues[temp]
    );
    setselectedAttribute(temp);
    if (!isHaveAttributes) {
      setCustomDropError("");
    } else {
      setselectedAttribute(temp);
      setCustomDropError("There are no custom attributes for this product");
    }

    setCustomAttributes(customAttributesData);
  }, [
    customAttributesData,
    expanded,
    availableProductOptions,
    customAttributesDropdownValues,
  ]);

  return (
    <div
      className={`${
        disable ? "pointer-events-none opacity-50 cursor-not-allowed" : ""
      }`}
    >
      <div className="flex mt-12">
        <div className="max-w-[250px] w-full mr-5">
          <DropDown
            name={"custom_attribute_defaults"}
            label={"Custom Attributes"}
            value={selectedAttribute}
            error={""}
            placeholder={"Search"}
            onChange={(val: any) => {
              const isHaveAttributes: boolean = isEmpty(
                customAttributesDropdownValues[val]
              );

              if (!isHaveAttributes) {
                setselectedAttribute(val);
                setCustomDropError("");
              } else {
                setCustomDropError(
                  "There are no custom attributes for this product"
                );
              }
            }}
            required={false}
            options={Object.keys(customAttributesDropdownValues).sort()}
            className="!h-[40px] text-[#163545]"
          />
        </div>

        <div className="ml-auto mt-auto">
          <TrestleButton
            onClick={() => {
              let temp = [...customAttributes];
              let obj: any = {
                productName: selectedAttribute,
                flag: "",
                defaultValue: "",
                customValue: "",
              };
              temp.push(obj);
              setCustomAttributes(temp);
              setCustomAttributesData(temp);
            }}
            label="Add Custom Attribute"
            type="primary"
            className="!h-[40px] !leading-[40px] !p-0 bg-[#E0EEF1] !text-[#0494B4]"
            disabled={!!customDropError}
          />
        </div>
      </div>
      {customDropError && (
        <InputErrortext className="flex ml-auto mt-1 !text-[#9ba8b0]">
          {AlertTrianleIcon}
          {customDropError}
        </InputErrortext>
      )}

      <div className="mt-6">
        {customAttributes.length ? (
          <TrestleTable
            isPaginated={false}
            headCells={headCellsAttributes}
            rows={customAttributes}
            hoverEffect={false}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CustomAttributes;
